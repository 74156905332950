//region AUTOCOMPLETE
/*
 * LOCATION AUTOCOMPLETE MODULE
 * */
jQuery.widget("app.locationAutocomplete",
{
    _create: function () {
        this.element.autocomplete({
            minLength: 2,
            mustMatch: true,
            matchContains: false,
            source: function (request, response) {
                $.ajax({
                    url: Enodo.rootUrl + "/MyAddresses/SearchLocations/",
                    data: {
                        searchValue: request.term,
                        countryCode: $('#' + $(this.element).attr('rel') + 'Countries').val()
                    },
                    success: function (data) {
                        response($.map(data,
                            function (location) {
                                return {
                                    id: location.LocationID,
                                    value: JSON.stringify(location),
                                    label: location.Locality + " " + location.State + " " + location.Postcode
                                };
                            }));
                    },
                    error: function (request, status, error) {
                        Enodo.log(2, 'QuickQuoteModule. startLocationAutocomplete. FAILED: ' + error);
                    }
                });
            },
            change: function (e, ui) {
                if (!ui.item) {
                    $(this).val("");
                }
            },
            select: function (event, ui) {
                var locationObj = JSON.parse(ui.item.value);

                $(this).data('locationId', ui.item.id);
                $(this).data('location', locationObj);

                $(this).attr('locationId', ui.item.id);
                $(this).attr('suburb', locationObj.Locality);
                $(this).attr('state', locationObj.State);
                $(this).attr('postcode', locationObj.Postcode);
                $(this).attr('countrycode', locationObj.CountryCode);
                $(this).attr('timezoneoffset', locationObj.TimeZoneOffset);
                $(this).val(ui.item.label).trigger("change");

                $(this).parent().parent().find('#senderName, #receiverName').attr('addressid', 0);
                $(this).parent().parent().find('#senderName, #receiverName').attr('accountid', 0);

                return false;
            },
            focus: function (event, ui) {
                this.value = ui.item.label;
                return false;
            },
            open: function () {
                $(this).removeClass("ui-corner-all").addClass("ui-corner-top");
            },
            close: function () {
                $(this).removeClass("ui-corner-top").addClass("ui-corner-all");
            }
        });
    }
});